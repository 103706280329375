import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function addCancellationReason(body){
    // console.log(body, "Lline55")
    const response = await axiosInstanceWithSecret.post(`/cancellation-reason/`, body, {
        headers:{
            "Content-Type": "application/json"
        }
    });

    return response;
 }

 export async function getAllCancellationReasons(userType){
    const response = await axiosInstanceWithSecret.get(`/cancellation-reason/?userType=${userType}`, {
    });
    return response;
 }

 export async function UpdateCancellation(id, body){
    const response = await axiosInstanceWithSecret.put(`/cancellation-reason/${id}/`, body);
    return response;
 }

 export async function updateCancellationReasonsOrder(body){
   const response = await axiosInstanceWithSecret.post(`/update-order-cancellation-reason`, body);
   return response;
}

 export async function DeleteCancellationReason(id){
    // const response = await axiosInstanceWithSecret.delete(`/cancellation-reason/${id}`);
    const response = await axiosInstanceWithSecret.delete(`/cancellation-reason/${id}/`);
    return response;
 }