/* eslint-disable no-undef */
import { faAddressBook, faCalendarCheck, faMoneyBillAlt, faBell } from "@fortawesome/free-regular-svg-icons";
import { faCog, faColumns, faHome, faMoneyCheckAlt, faStar, faUser, faUserTie, faFile, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AccessSettings } from "../../../GlobalComps/Constant";
import { setDocumentTitle } from "../../../GlobalComps/DynamicTitle";
import AdminContext from "../../AdminContext/AdminContext";
// import logo from "../../assets/images/logo-icon.png"


// import ReactDOM from "react-dom";

class SidebarNav extends Component {
    constructor(props){
      super(props);
      this.state={
        show: null
      }
    }

    static contextType = AdminContext

    componentDidMount() {
      let $slimScrolls = $(".slimscroll")
      if($slimScrolls.length > 0) {
        $slimScrolls.slimScroll({
          height: 'auto',
          width: '100%',
          position: 'right',
          size: '7px',
          color: '#ccc',
          allowPageScroll: false,
          wheelStep: 10,
          touchScrollStep: 100
        });
        var wHeight = $(window).height() - 60;
        $slimScrolls.height(wHeight);
        $('.sidebar .slimScrollDiv').height(wHeight);
        $(window).resize(function() {
          var rHeight = $(window).height() - 60;
          $slimScrolls.height(rHeight);
          $('.sidebar .slimScrollDiv').height(rHeight);
        });
      }

      $('#sidebar-menu a').on('click', function(e) {
        if($(this).parent().hasClass('submenu')) {
          e.preventDefault();
        }
        if(!$(this).hasClass('subdrop')) {
          $('ul', $(this).parents('ul:first')).slideUp(350);
          $('a', $(this).parents('ul:first')).removeClass('subdrop');
          $(this).next('ul').slideDown(350);
          $(this).addClass('subdrop');
        } else if($(this).hasClass('subdrop')) {
          $(this).removeClass('subdrop');
          $(this).next('ul').slideUp(350);
        }
      });
      $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');


    }
  handleShow(id){
    this.setState({
        show: id
    })
  }

onhandleCloseMenu = (e) => {
  let allElements = Array.from(document.querySelectorAll('.main-wrapper.slide-nav'))
  for (let element of allElements) {
    element.classList.remove('slide-nav')
  }
  let allElements1 = Array.from(document.querySelectorAll('.sidebar-overlay.opened'))
  for (let element of allElements1) {
    element.classList.remove('opened')
  }
  };

  // componentDidMount = () => {
  //   setDocumentTitle('Admin | Dashboard');
  // }

  handleMenuClick = (e, title) => {
    this.onhandleCloseMenu(e);
    setDocumentTitle('Admin | '+title);
  }

  checkAccess = (currRole) => {
    const userAccessRole = [...this.context.adminUserData?.adminAccessRoleSettings]
    if(userAccessRole.includes(AccessSettings.ALL_ACCESS)){
      return true;
    }
    else if(userAccessRole.includes(currRole)){
      return true;
    }
    return false;
  }


  render() {

   const {  location } = this.props
   let pathname = location.pathname

   return (
    <div className="sidebar" id="sidebar">
    <div className="sidebar-logo">
      <a href="/admin">MrSolvo</a>
    </div>
    <div className="sidebar-inner slimscroll">
      <div id="sidebar-menu" className="sidebar-menu">
        <ul>
          <li className={`${'/admin' === pathname ?'active' : '' }`}>
              <Link to="/admin" exact="true"><FontAwesomeIcon icon={faHome} /><span>Dashboard</span></Link>
          </li>
          { this.checkAccess(AccessSettings.CATEGORY) && <li className={`${'/admin/category' === pathname || '/admin/add-categories' === pathname || '/admin/edit-categories' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Categories')}>
              <Link to="/admin/category" exact="true"><FontAwesomeIcon icon={faColumns} /><span>Categories</span></Link>
          </li>
          }
          { this.checkAccess(AccessSettings.ADMIN_USERS) && <li className={`${'/admin/list-admin-users' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Categories')}>
              <Link to="/admin/list-admin-users" exact="true"><FontAwesomeIcon icon={faColumns} /><span>Admin Users</span></Link>
          </li>
          }
          { this.checkAccess(AccessSettings.CONTACTS) &&
            <li className={`${'/admin/list-contacts' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, '')}>
              <Link to="/admin/list-contacts" exact="true"><FontAwesomeIcon icon={faAddressBook} /><span>Contact Leads</span></Link>
          </li>
          }
          {/* <li className={`${'/admin/services' === pathname || '/admin/services-view' === pathname  ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Services')}>
              <Link to="/admin/services" exact="true"> <i className="fas fa-bullhorn"></i><span>Services</span></Link>
            </li> */}
            { this.checkAccess(AccessSettings.SERVICE_PROVIDER) && <li className={`${'/admin/service-provider' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Service provider')}>
              <Link to="/admin/service-provider" exact="true"> <FontAwesomeIcon icon={faUserTie} /><span>Service provider</span></Link>
            </li>
            }
            { this.checkAccess(AccessSettings.DOCUMENTS) && <li className={`${'/admin/documents' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Documents')}>
              <Link to="/admin/documents" exact="true"> <FontAwesomeIcon icon={faFile} /><span>Documents</span></Link>
            </li>
            }
            { this.checkAccess(AccessSettings.BOOKINGS) && <li className={`${'/admin/bookings' === pathname  ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Booking List')}>
              <Link to="/admin/bookings" exact="true"><FontAwesomeIcon icon={faCalendarCheck} /><span>Booking List</span></Link>
            </li>
            }
            {
              <li className={`${'/admin/service-provider-bank-details' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 'Bank Details')}>
                <Link to="/admin/service-provider-bank-details" exact="true"> <FontAwesomeIcon icon={faMoneyCheckAlt} /> <span>Bank Details</span></Link>
              </li>
            }
            {
              this.checkAccess(AccessSettings.TRACK_BOOKING_JOURNEY) && <li className={`${'/admin/track-booking-journey' === pathname  ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'track-booking-journey')}>
              <Link to="/admin/track-booking-journey" exact="true"><FontAwesomeIcon icon={faBell} /><span>Booking Journey</span></Link>
            </li>
            }
            { this.checkAccess(AccessSettings.PAYMENT) && <li className={`${'/admin/payments' === pathname  ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Payments')}>
              <Link to="/admin/payments" exact="true"><FontAwesomeIcon icon={faMoneyCheckAlt} /><span>Payments</span></Link>
            </li>
            }
            {/* { this.checkAccess(AccessSettings.RATINGS) && <li className={`${'/admin/ratingtype' === pathname || '/admin/add-ratingtype' === pathname || '/admin/edit-ratingtype' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Rating Type')}>
              <Link to="/admin/ratingtype" exact="true"> <FontAwesomeIcon icon={faStarHalfAlt} /><span>Rating Type</span></Link>
            </li>
            } */}
            { this.checkAccess(AccessSettings.RATINGS) && <li className={`${'/admin/rating' === pathname  ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, '')}>
              <Link to="/admin/rating" exact="true"> <FontAwesomeIcon icon={faStar} /> <span>Ratings</span></Link>
            </li>
            }
            { this.checkAccess(AccessSettings.SUBSCRIPTION_PLAN) && <li className={`${'/admin/subscriptions' === pathname || '/admin/add-subscriptions' === pathname || '/admin/edit-subscriptions' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Subscriptions')}>
              <Link to="/admin/subscriptions" exact="true"> <FontAwesomeIcon icon={faMoneyBillAlt} /> <span>Subscriptions</span></Link>
            </li>
            }
            {/* { this.checkAccess(AccessSettings.PAYMENT) && <li className={`${'/admin/wallet' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Wallet')}>
              <Link to="/admin/wallet" exact="true"> <FontAwesomeIcon icon={faWallet} /> <span>Wallet</span></Link>
            </li>
            } */}
            { this.checkAccess(AccessSettings.USERS) && <li className={`${'/admin/user' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'User')}>
              <Link to="/admin/user" exact="true"> <FontAwesomeIcon icon={faUser} /> <span>User</span></Link>
            </li>
            }
            {
               <li className={`${'/admin/posts' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 'Posts')}>
                 <Link to="/admin/posts" exact="true"> <FontAwesomeIcon icon={faColumns} /> <span>Posts</span></Link>
               </li>
            }
            {
               <li className={`${'/admin/clickwrap-agreement' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 'clickwrap-agreement')}>
                 <Link to="/admin/clickwrap-agreement" exact="true"> <FontAwesomeIcon icon={faFile} /> <span>Agreement</span></Link>
               </li>
            }
                        {
               <li className={`${'/admin/notification' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 'notification')}>
                 <Link to="/admin/notification" exact="true"> <FontAwesomeIcon icon={faBell} /> <span>Notification</span></Link>
               </li>
            }
             {
               <li className={`${'/admin/query-msg' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 'query-msg')}>
                 <Link to="/admin/query-msg" exact="true"> <FontAwesomeIcon icon={faQuestion} /> <span>Query Message</span></Link>
               </li>
            }
            {
               <li className={`${'/admin/s3-bucket-manager' === pathname ? 'active' : ''}`} onClick={(e) => this.handleMenuClick(e, 's3-bucket-manager')}>
                 <Link to="/admin/s3-bucket-manager" exact="true"> <FontAwesomeIcon icon={faQuestion} /> <span>s3 Bucket Manager</span></Link>
               </li>
            }
            { this.checkAccess(AccessSettings.SETTINGS) && <li className={`${'/admin/settings' === pathname ? 'active' : '' }`} onClick={(e) => this.handleMenuClick(e, 'Settings')}>
              <Link to="/admin/settings" exact="true"> <FontAwesomeIcon icon={faCog} /> <span>Settings</span></Link>
            </li>
            }
        </ul>
      </div>
    </div>
  </div>
  );
}
}

export default withRouter(SidebarNav);
