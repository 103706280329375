import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Table, Badge } from "react-bootstrap";
import {
    DeleteCancellationReason,
  UpdateCancellation,
  addCancellationReason,
  getAllCancellationReasons,
  updateCancellationReasonsOrder
} from "../../../../../API/CancellationAPI";
import Swal from "sweetalert2";

const ServiceProviderCan = () => {
  const [providerReasons, setProviderReasons] = useState([]);
  const [currentReason, setCurrentReason] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const response = await getAllCancellationReasons("ServiceProvider");
        setProviderReasons(response.data.results);
      } catch (error) {
        console.error("Error fetching cancellation reasons:", error);
      }
    };

    fetchAll();
  }, []);

  // Handle drag start
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("draggedIndex", index);
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping
  };

  // Handle drop
  const handleDrop = async (e, dropIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("draggedIndex"), 10);
    if (draggedIndex === dropIndex) return;

    // Reorder the list
    const updatedReasons = [...providerReasons];
    const [draggedItem] = updatedReasons.splice(draggedIndex, 1);
    updatedReasons.splice(dropIndex, 0, draggedItem);

    setProviderReasons(updatedReasons);

    // Extract the ordered IDs for the backend
    const orderedIds = updatedReasons.map((reason) => reason.reasons_id);


    // Call the backend API to update the order
    try {
        await updateCancellationReasonsOrder({ ordered_ids: orderedIds })
        console.log("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with this action?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setProviderReasons((prev) => prev.filter((reason) => reason.reasons_id !== id));
        const resp = await DeleteCancellationReason(id);
        if (resp.status === 204) {
          Swal.fire({
            title: "Deleted!",
            text: "Your item has been deleted.",
            icon: "success",
          });
        }
      }
    });
  };

  const openModal = (reason = null) => {
    setCurrentReason(reason || { status: "", service_type: "", reason: "", repercussion: "",  user_type: "ServiceProvider" });
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      let resp;
      if (currentReason.reasons_id) {
        // Update an existing reason
        resp = await UpdateCancellation(currentReason.reasons_id, currentReason);
      } else {
        //add a new reason
        let updatedCurrentReason = {...currentReason, order:providerReasons.length + 1 }
        resp = await addCancellationReason(updatedCurrentReason);
      }

      if (resp.status === 200 || resp.status === 201) {
        let updatedReasons
        if (currentReason.reasons_id){
            updatedReasons = providerReasons.map((reason) =>
                  reason.reasons_id === currentReason.reasons_id
                    ? { ...reason, ...currentReason }
                    : reason
                )
        }else{
            updatedReasons = [...providerReasons, resp.data]
        }
        setProviderReasons(updatedReasons);
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error saving reason:", error);
    }
  };


  const getRowColor = (reason) => {
    const pairKey = `${reason.status}-${reason.service_type}`;
    console.log("Generated pairKey:", pairKey); // Debugging
    const colorMapping = {
      "PRE-Service": "#d1e7dd",
      "POST-Service": "#d1c4e9",
      "PRE-Consultation": "#fff3cd",
      "POST-Consultation": "#d1ecf1",
      "PRE-Retainership": "#f5c6cb",
      "POST-Retainership": "#c3e6cb",
      "PRE-AddOnService": "#ffeeba",
      "POST-AddOnService": "#bee5eb",
    };
    console.log("Mapped Color:", colorMapping[pairKey]); // Debugging
    return colorMapping[pairKey] || "#ffffff"; // Default to white if no match
  };

  return (
    <div className="container py-4">
      <h2 className="mb-4">Service Provider Cancellation Reasons</h2>
      <Table striped bordered hover className="mb-4">
        <thead>
          <tr>
            <th>Cancellation Type</th>
            <th>Service Type</th>
            <th>Reason</th>
            <th>Repercussion</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {providerReasons.map((reason, index) => (
            <tr
              key={reason.reasons_id}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
            >
            <td>
                <div
                style={{
                    backgroundColor: getRowColor(reason), // Dynamic background color
                    color: "#000", // Text color
                    padding: "0.25em 0.5em", // Badge padding
                    fontSize: "75%", // Font size like Bootstrap badge
                    fontWeight: "700", // Bold text
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    verticalAlign: "baseline",
                    borderRadius: "0.25rem", // Rounded corners
                    display: "inline-block", // Inline-block for proper alignment
                }}
                className="d-inline-block" // Ensure proper spacing/alignment from Bootstrap
                >
                {reason.status === "PRE" ? "Pre-Acceptance" : "Post-Acceptance"}
                </div>

            </td>
            <td>
                <div
                style={{
                    backgroundColor: getRowColor(reason), // Dynamic background color
                    color: "#000", // Text color
                    padding: "0.25em 0.5em", // Badge padding
                    fontSize: "75%", // Font size like Bootstrap badge
                    fontWeight: "700", // Bold text
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    verticalAlign: "baseline",
                    borderRadius: "0.25rem", // Rounded corners
                    display: "inline-block", // Inline-block for proper alignment
                }}
                className="d-inline-block" // Ensure proper spacing/alignment from Bootstrap
                >
                {reason.service_type}
                </div>
            </td>


            <td>{reason.reason}</td>
            <td>{reason.repercussion}</td>
            <td>
                <Button variant="primary" size="sm" onClick={() => openModal(reason)}>
                  Edit
                </Button>

              </td>
              <td>
              <Button variant="danger" size="sm" className="ms-2" onClick={() => handleDelete(reason.reasons_id)}>
                  Delete
                </Button>

              </td>

            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" onClick={() => openModal()}>
        Add New Reason
      </Button>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentReason?.reasons_id ? "Edit Reason" : "Add New Reason"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Cancellation Type</Form.Label>
              <Form.Select
                name="status"
                value={currentReason?.status || ""}
                onChange={(e) => setCurrentReason({ ...currentReason, status: e.target.value })}
              >
                <option value="">Select</option>
                <option value="PRE">Pre-Acceptance</option>
                <option value="POST">Post-Acceptance</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Select
                name="service_type"
                value={currentReason?.service_type || ""}
                onChange={(e) => setCurrentReason({ ...currentReason, service_type: e.target.value })}
              >
                <option value="">Select</option>
                <option value="Service">Service</option>
                <option value="Consultation">Consultation</option>
                <option value="Retainership">Retainership</option>
                <option value="AddOnService">Add-On Service</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Reason</Form.Label>
              <Form.Control
                type="text"
                name="reason"
                value={currentReason?.reason || ""}
                onChange={(e) => setCurrentReason({ ...currentReason, reason: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Repercussion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="repercussion"
                value={currentReason?.repercussion || ""}
                onChange={(e) => setCurrentReason({ ...currentReason, repercussion: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServiceProviderCan;
