import React, { useState } from "react";
import SidebarNav from "../sidebar";
import { Table, Input, Button, Modal } from "antd";
import Swal from "sweetalert2";
import { getS3BucketData, removeObjectS3 } from "../../../API/S3BucketAPI";
import { Env } from "../../../Env"
import { DatePicker } from "antd";
import moment from "moment";
const S3BucketManager = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");


  // const [filteredData, setFilteredData] = useState(data); // Stores filtered dataset
  const [selectedDate, setSelectedDate] = useState(null); // Stores selected date

const handleDateFilter = async (date) => {
  console.log(date, "lindiou")
  if (!date) {
    // setFilteredData(data); // Reset if no date selected
    setSelectedDate(null);
    return;
  }

  setSelectedDate(date);

  // Filter data where uploaded_at matches the selected date
  // const response = await getS3BucketData(date);
  // setFilteredData(response.data.results);
};

  const fetchS3BucketData = async () => {
    try {
      setLoading(true);
      const response = await getS3BucketData();
      console.log(response.data.results, "lind973909")
      setData(response.data.results || []);
    } catch (error) {
      console.error("Error fetching queries:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (key, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await removeObjectS3(key, id);
          setData((prevData) => prevData.filter((item) => item.file_name !== key));
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } catch (error) {
          console.error(`Error deleting object with key ${key}:`, error);
          Swal.fire("Error!", "There was an issue deleting the file.", "error");
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedImage("");
  };

  // const filteredData = data.filter((item) =>
  //   item.key.toLowerCase().includes(searchText.toLowerCase())
  // );

  const columns = [
    {
      title: "Name",
      dataIndex: "key",
      render: (_, record) => <>{record.file_name}</>,
    },
    {
      title: "Preview",
      dataIndex: "url",
      render: (_, record) => (
        <img
          src={`${Env.S3_HOSTNAME}${record.file_name}`}
          alt="Preview"
          style={{ maxWidth: "100px", maxHeight: "100px", cursor: "pointer" }}
          onClick={() => handleImageClick(`${Env.S3_HOSTNAME}${record.file_name}`)}
        />
      ),
    },
    {
      title: "Uploaded At",
      dataIndex: "date",
      render: (_, record) => (
        <>{new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }).format(new Date(record.uploaded_at))}</>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Button type="primary" danger onClick={() => handleDelete(record.file_name, record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">S3 Bucket Manager</h3>
              </div>
            </div>
          </div>

          {/* Button in the center */}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Button type="primary" size="large" onClick={fetchS3BucketData}>
              Fetch S3 Bucket Data
            </Button>
          </div>

          {/* Search Input */}
          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                placeholder="Search by name"
                value={searchText}
                onChange={handleSearch}
                allowClear
              />
            </div>
          </div>

          {/* Table */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {/* Date Picker for Filtering */}
    <DatePicker
      onChange={handleDateFilter}
      value={selectedDate}
      format="YYYY-MM-DD"
      placeholder="Select Upload Date"
    />
                    <Table
                      className="table table-hover table-center mb-0 datatable"
                      style={{ overflowX: "auto", width: "100%" }}
                      columns={columns}
                      dataSource={data}
                      rowKey={(record) => record.id}
                      loading={loading}
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      }}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Modal */}
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        centered
      >
        <img
          src={selectedImage}
          alt="Zoomed Preview"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>
    </>
  );
};

export default S3BucketManager;
