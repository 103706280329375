import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchChatsByUsers } from '../../../API/QueryChatAPI';

const QueryDetails = ({ showModal, handleCloseModal, selectedQuery }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchChats = async () => {
      if (selectedQuery && selectedQuery.provider && selectedQuery.user) {
        setLoading(true);
        try {
          // You'll need to create this API function
          const response = await fetchChatsByUsers(selectedQuery.provider.id, selectedQuery.user.id);
          console.log(response, "alkthuw34o8")
          setChats(response.data.data || []);
        } catch (error) {
          console.error('Error fetching chats:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchChats();
  }, [selectedQuery]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Query Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedQuery && (
          <div>
            <h5>Customer Information</h5>
            <p><strong>Name:</strong> {selectedQuery.user.name}</p>
            <p><strong>Phone:</strong> {selectedQuery.user.phone}</p>

            <h5 className="mt-4">Service Provider Information</h5>
            <p><strong>Name:</strong> {selectedQuery.provider.name}</p>
            <p><strong>Phone:</strong> {selectedQuery.provider.phone}</p>

            {/* Chat section can be added here */}
            <h5 className="mt-4">Chat History</h5>
            <div className="chat-container">
              {/* We'll add chat messages here once we set up the API */}
              {loading ? (
                <p>Loading chats...</p>
              ) : (
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {chats.map((chat, index) => (
                    <div
                      key={index}
                      style={{
                        margin: '10px 0',
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid #dee2e6'
                      }}
                    >
                      <div style={{ fontWeight: 'bold', color: '#0d6efd', marginBottom: '5px' }}>
                        {chat.sender_username}
                      </div>
                      <div style={{ color: '#212529' }}>
                        {/* {chat.data.text} */}
                        {chat.data.text ? chat.data.text : chat.service_query_data?.requirements}
                        {/* chat.service_query_data.requirements */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QueryDetails;
