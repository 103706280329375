import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";
// Get all grouped chats for admin
export const getS3BucketData = async (date) => {
    try {
        const response = await axiosInstanceWithSecret.get(`s3/list`, {
            params: {
                uploaded_at: date
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};


// Get all grouped chats for admin
export const removeObjectS3 = async (key, id) => {
    try {
        const response = await axiosInstanceWithSecret.post(`s3/delete`, {
            key: key, // Send the key in the request body
            id: id
        });
        return response;
    } catch (error) {
        throw error;
    }
};
