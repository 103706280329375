import * as React from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import { toggleValueInArray } from './Helper';
import { Checkbox, FormControl, Input, InputLabel, ListItemText, ListSubheader, MenuItem, 
  // OutlinedInput, 
  Select } from '@mui/material';
import { styled } from '@mui/material/styles';


const useStyles = styled((theme) => ({
  root: {
    whiteSpace: "unset",
    wordBreak: "break-all"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuPaper: {
    maxHeight: 500
  }
}));


export default function DropdownGroupMultiselectNew({ width, selectControlProps, required, value, onchange, options, label }) {
  const classes = useStyles();
  // const handleChange = (selectedCheck) => {
  //   onchange(
  //       toggleValueInArray(value, selectedCheck)
  //   );
  // };
    // Check and append values from value array to options if they don't exist
    value?.forEach(item => !options?.[item.subCategory]?.includes(item.value) && options?.[item.subCategory]?.push(item.value));

  const handleChange = (i, group, option) => {
    const isSelected = value.some(item => item.subCategory ? (item.subCategory === group && item.value === option) : (item.value === option));
    let updatedValues;
  
    if (isSelected) {
      updatedValues = value.filter(item => item.subCategory ? !(item.subCategory === group && item.value === option) : !(item.value === option));
    } else {
      // updatedValues = [...value, { group, value: option }];
      updatedValues = [...value, { subCategory: group, value: option, i}];
    }
    updatedValues.sort((a, b) => a.i - b.i);
  
    onchange(updatedValues);
  };


  const selectedValues = Array.isArray(value) ? value : [value];
  return (
      <FormControl fullWidth={true} variant='standard'>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          required={required}
          // className={`w-full md:w-[${width}]`}
          value={value}
          {...selectControlProps}
          input={<Input style={{width: width}} variant='standard' label={label}/>}
          // renderValue={(selected) => selected.join(', ')}
          renderValue={( ) =>
            selectedValues.map(item => item.value).join(', ').substring(0, 12) +
            (selectedValues.length > 1 ? '...' : '')
          }
          MenuProps={{ classes: { paper: classes.menuPaper } }}
        >
          {Object.keys(options || {})?.map((group) => (
          [
            <ListSubheader onClick={() => handleChange(group, group)} key={group} className='bg-primary font-bold bg-blue-200 flex items-center text-white'> 
            {/* <MenuItem onClick={()=>handleChange(group)} key={group} className='bg-primary font-bold bg-blue-200 flex items-center'> */}
              {/* <Checkbox value={group} checked={value?.includes(group)} /> */}
                {/* <ListItemText primary={group} /> */}
                <ListItemText  style={{fontStyle: 'italic'}} primary={group} />
                </ListSubheader>,
            options?.[group]?.map((option, i) => (
              // <MenuItem onClick={()=>handleChange(option)} key={option} value={option}>
              <MenuItem classes={{ root: classes.root }} onClick={() => handleChange(i, group, option)} key={option} value={option}>
                {/* <Checkbox value={option} checked={value?.includes(option)} />
                <ListItemText primary={option} /> */}
                <Checkbox
                    value={option}
                    checked={value.some(item => item.subCategory ? (item.subCategory === group && item.value === option) : (item.value === option) )}
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))
          ]
        ))}
        </Select>
      </FormControl>
  );
}

DropdownGroupMultiselectNew.defaultProps = {
  width: '8rem'
}